.page-header {
  @apply grid mb-4 gap-10;

  grid-template-columns: minmax(50%, min-content) 1fr;
}

.page-header__controls {
  @apply flex flex-row items-center gap-5 ml-auto;

  height: 46px;
}

.page-header__button {
  @apply flex items-center m-0 p-0 h-auto;
}

.page-header__button:disabled {
  @apply pointer-events-none opacity-50;
}
