.svg {
  @apply inline-block;
}

.svg--smallest {
  @apply w-3 h-3;
}

.svg--smaller {
  width: 14px;
  height: 14px;
}

.svg--small {
  @apply w-4 h-4;
}

.svg--medium {
  width: 18px;
  height: 18px;
}

.svg--large {
  @apply w-5 h-5;
}

.svg--xlarge {
  @apply w-6 h-6;
}

.svg--primary {
  @apply bg-primary;
}

.svg--gray {
  @apply bg-grayscale-600;
}

.svg--lightgray {
  @apply bg-grayscale-500;
}

.svg--black {
  @apply bg-grayscale-900;
}

.svg--white {
  @apply bg-white;
}

.svg--success {
  @apply bg-success-500;
}

.svg--warning {
  @apply bg-warning-500;
}

.svg--error {
  @apply bg-error-500;
}
