.avatar-upload-form {
  @apply flex flex-col gap-4;
}

.avatar-upload-form__dropzone {
  @apply flex flex-col items-center p-4 border border-dashed border-grayscale-400;
}

.avatar-upload-form__preview {
  @apply flex items-center border border-dashed border-grayscale-400 mb-5;
}
