.app-container {
  @apply w-screen h-screen flex flex-col tablet:grid;
}

.app-container--white .app-view-container {
  @apply bg-white;
}

.app-container--nav {
  @apply grid-cols-1 sm:grid-cols-flowfi-base;
}

.app-container--nav-condensed {
  @apply grid-cols-flowfi-condensed;
}

.app-navigation {
  @apply flex flex-col h-full p-3 bg-white border-r border-r-grayscale-300 relative shadow-flowfi isolate;

  z-index: 51;
}

.app-navigation--mobile {
  @apply p-0 relative border-b border-b-grayscale-300;

  height: 64px;
}

.app-view-container {
  @apply flex flex-col flex-1 relative w-full h-full px-4 pb-4 overflow-auto bg-grayscale-100 tablet:px-10 tablet:pb-10;
}

.app-view-container .page-header {
  @apply mt-4 tablet:mt-10;
}

.app-view-container section {
  @apply flex-grow;
}
