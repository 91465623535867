.app-navigation:hover .navigation-toggle {
  @apply opacity-100;
}

.navigation-toggle {
  @apply opacity-0 flex items-center justify-center w-6 h-6 rounded-full bg-white border-2 border-grayscale-300 cursor-pointer absolute transition-opacity ease-in-out;
  top: 26px;
  right: -12px;
  z-index: 10;
}

.navigation-items__wrap {
  @apply p-4 flex flex-col flex-grow;
}

.navigation-items {
  @apply flex flex-col gap-3;
}

.navigation-items .navigation-item__link {
  padding: 10px;
}

.navigation-item {
}

.navigation-item--collapsed .navigation-item__link {
  @apply gap-0;
}

/* this feels bad, but the badge doesn't care about collapsed states */
.navigation-item--collapsed .badge {
  @apply relative -top-1 -left-1 border border-white;
}

.navigation-item__link {
  @apply flex flex-row items-center gap-2 font-bold text-grayscale-900 hover:text-primary;

  padding: 14px;
  border-radius: 10px;
}

.navigation-item__link--active {
  @apply bg-grayscale-100 text-primary;
}

/* mobile menu */
.app-navigation__header {
  @apply h-full flex items-center gap-4 px-4;
}

.app-navigation__drawer {
  @apply flex flex-col fixed w-screen h-screen bg-white z-10 isolate will-change-transform;

  transform: translateX(-100vw);
  transition: 0.2s transform ease-in-out;
}

.app-navigation__drawer--open {
  transform: translateX(0);
}

.app-navigation__drawer-header {
  @apply flex items-center px-4 relative border-b border-b-grayscale-300;

  height: 64px;
}

.app-navigation__drawer-dismiss {
  @apply absolute flex items-center;

  left: 12px;
}

.navigation-controls--mobile {
  @apply ml-auto flex flex-row items-center gap-3;
}
