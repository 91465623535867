.entry-form__section {
  @apply flex flex-col !flex-grow-0 mx-auto my-auto items-center;

  width: 350px;
}

.entry-form__divider {
  @apply w-full h-1 border-t border-grayscale-300 my-8 text-center;
}

.entry-form__divider-text {
  @apply px-3 pt-1 relative text-grayscale-600 text-sm bg-white;

  top: -13px;
}

.entry-form__header {
  @apply mb-3 text-center;
}

.entry-form__description {
  @apply mb-10 text-center;
}

.entry-form {
  @apply w-full flex flex-col gap-3;
}

.entry-form__forgot-password {
  @apply self-start;
}
