.menu {
  @apply relative inline-block text-left;
}

.menu__trigger {
  @apply ml-auto p-0 !w-8 !h-8;
}

.menu__items {
  @apply absolute z-50 right-0 mt-2 origin-top-right flex flex-col gap-3 px-1 py-2 bg-white rounded-lg border border-grayscale-300 shadow-flowfi;

  width: 240px;
}

.menu__item {
  @apply text-sm text-left text-grayscale-900 p-2 rounded-lg hover:bg-grayscale-100;
}
