.form-field {
  @apply w-full;
}

.form-field__password-toggle {
  @apply absolute w-10 h-10 right-0 flex flex-col items-center justify-center;

  top: 1px;
}
.form-field__password-toggle input {
  @apply absolute top-0 right-0 w-full h-full opacity-0 cursor-pointer;
}

/* Checkbox */
.form-field--checkbox {
  @apply inline-flex flex-row items-center gap-2;
}

.form-field--checkbox .form-field__input {
  @apply m-0;
}

.form-field--checkbox .form-field__label {
  @apply text-grayscale-900;
}

/* Radio */
.form-field--radio {
  @apply inline-flex flex-row items-center gap-2;
}

.radio {
  @apply relative w-4 h-4;
}

.radio input {
  @apply absolute top-0 bottom-0 right-0 left-0 cursor-pointer opacity-0;
}

.radio input:disabled {
  @apply cursor-not-allowed;
}

.radio-indicator {
  @apply absolute border border-grayscale-300 rounded-full w-full h-full bg-white pointer-events-none;
}

.radio input:checked + .radio-indicator {
  @apply bg-primary border-primary;

  box-shadow: 0 0 0 3px #fff inset;
}

.radio input:disabled + .radio-indicator {
  @apply bg-grayscale-200;
}

.form-field--radio .form-field__label {
  @apply flex gap-2 items-center text-grayscale-900;
}

/* Checkbox */
.checkbox {
  @apply relative w-4 h-4;
}

.checkbox input {
  @apply absolute top-0 bottom-0 right-0 left-0 cursor-pointer opacity-0;
}

.checkbox input:disabled {
  @apply cursor-not-allowed;
}

.checkbox-indicator {
  @apply absolute border border-grayscale-300 w-full h-full bg-white pointer-events-none;

  border-radius: 4px;
}

.checkbox input:checked + .checkbox-indicator {
  @apply bg-primary border-primary;
}

.checkbox input:checked + .checkbox-indicator::after {
  @apply block absolute border-white border-r-2 border-b-2;

  top: 2px;
  left: 4px;
  width: 5px;
  height: 8px;
  content: "";
  transform: rotate(45deg);
}

.checkbox-indicator--indeterminate {
  @apply bg-primary border-primary;
}

.checkbox-indicator--indeterminate::after {
  @apply block absolute !border-r-0 !border-b-0 border-white border-t-2;

  top: 6px !important;
  left: 3px !important;
  width: 8px !important;
  height: 0 !important;
  transform: none !important;
  content: "";
}

.checkbox input:disabled + .checkbox-indicator {
  @apply bg-grayscale-200;
}

.form-field--checkbox .form-field__label {
  @apply flex gap-2 items-center text-grayscale-900;
}

/* Select */
.select {
  @apply relative;
}

.select__trigger {
  @apply p-2 pr-7 w-full border border-grayscale-300 relative text-left transition ease-in-out duration-150;

  border-radius: 10px;
}

.select__trigger-text {
  @apply truncate;
}

.select__trigger-icon {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;
}

.select__popover {
  @apply absolute mt-1 w-full max-h-60 flex flex-col gap-3 p-1 bg-white rounded-lg border border-grayscale-300 shadow-flowfi overflow-auto z-10;
}

.select__popover-item {
  @apply p-2 text-sm text-grayscale-900 bg-white rounded-lg cursor-default select-none;
}

.select__popover-item--active {
  @apply bg-grayscale-100;
}

.select__popover-item-text {
  @apply block truncate font-normal;
}

.select__popover-item-text--active {
  @apply font-semibold;
}

/* Select With Filter / Combobox */
.combobox__trigger {
  @apply absolute flex flex-col items-center justify-center;

  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
}

/* Toggle */
button.toggle {
  @apply relative inline-flex h-6 w-11 items-center rounded-full bg-grayscale-500;
}

button.toggle--active {
  @apply bg-primary;
}

.toggle-indicator {
  @apply inline-block h-4 w-4 rounded-full bg-white transform transition ease-in-out duration-200 translate-x-1;
}

.toggle-indicator--active {
  @apply translate-x-6;
}

/* Rich Text */
/* Extends react-quill/dist/quill.snow.css" */
.ql-container.ql-snow.ql-disabled {
  @apply bg-grayscale-100 !border-t !rounded-tl-lg !rounded-tr-lg;
}

.ql-toolbar.ql-snow {
  @apply !border-grayscale-300 !border-b-0 !rounded-tl-lg !rounded-tr-lg flex flex-row;
}

.ql-container.ql-snow {
  @apply !border-grayscale-300 !border-t-0 !rounded-bl-lg !rounded-br-lg;
}

.ql-snow.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #a0aec0;
  color: #a0aec0;
}

.ql-snow.ql-toolbar .ql-fill {
  fill: #a0aec0;
  stroke: none;
  color: #a0aec0;
}

.ql-snow.ql-toolbar .ql-picker {
  color: #a0aec0;
}

.ql-tooltip.ql-editing {
  margin-left: 50px; /* Nudge the tooltip so it's not getting cut off at the leftmost bound of the view */
}

/* Error states */

.form-field--disabled .form-field__input,
.form-field--disabled .select__trigger,
.form-field--disabled .react-datepicker__input-container input {
  @apply bg-grayscale-100 cursor-not-allowed;
}

.form-field__error {
  @apply mt-1;

  font-size: 11px;
}

.form-field--invalid .form-field__label,
.form-field--invalid .form-field__error {
  @apply text-error;
}

.form-field--invalid .form-field__input,
.form-field--invalid .checkbox-indicator {
  @apply border-error;
}
