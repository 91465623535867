.account-tree-wrap {
  @apply mt-4 mb-8 pt-4 border-t border-grayscale-300 overflow-auto;

  max-height: 330px;
}

.account-tree ul {
  @apply ml-5;
}

.account-tree .account-tree {
  @apply my-0;

  margin-left: 45px;
}

.account-item {
  @apply m-0 py-2 pl-6 relative;
}

.account-item--has-children {
  @apply pl-0;
}

.account-item--hidden {
  @apply hidden;
}

.account-tree--hidden {
  @apply hidden;
}

.account-tree .account-item:last-child {
  @apply pb-0;
}

.account-tree .account-tree .account-item:before {
  @apply absolute block border-l h-full border-grayscale-300;

  left: -13px;
  top: 4px;
  width: 1px;
  content: "";
}
