.action-item-group {
  @apply flex flex-col gap-4 mb-10;
}

.action-item-group__header {
  @apply flex flex-row gap-4 items-center cursor-pointer;
}

.action-item-group__items {
  @apply flex flex-col gap-4;
}

.action-item {
  @apply rounded-lg border bg-white border-grayscale-300;
}

.action-item__category {
  @apply flex flex-row items-center gap-2 mb-5 text-primary text-xs;
}

/* Status/Notifications */
.action-item__status {
  @apply w-full;
}

.action-item__due-date {
  @apply text-grayscale-500 text-sm;
}

.action-item__done {
  @apply text-success-900 text-sm;
}

.action-item__notification {
  @apply flex flex-row items-center gap-2 h-10 font-semibold text-xs;
}

.action-item__notification--overdue {
  @apply text-warning;
}

.action-item__notification--submitted {
  @apply text-success-900;
}

.action-item__reply {
  @apply flex flex-row items-center py-1 px-4 border-t border-grayscale-300;
}

.action-item__reply--form-visible {
  @apply flex-col pt-4;
}

/* Forms */
.action-item__form {
  @apply w-full;
}

.action-item__form-controls {
  @apply flex justify-end my-4;
}

.action-item-form__amortization-fields {
  @apply grid grid-cols-1 gap-4 mb-4 tablet:mb-0 tablet:grid-cols-3;
}

.action-item-form__notes-toggle {
  @apply text-grayscale-500 text-sm;
}

/* Rich Text */

.action-item__question,
.ql-editor {
  @apply !text-grayscale-900 text-sm;
}

.action-item__question p,
.ql-editor p {
  @apply !text-grayscale-900 !text-sm !my-2;
}

.ql-editor ul,
.ql-editor ol {
  @apply !pl-0;
}

.action-item__question ul {
  @apply !list-disc !pl-4 !my-2 !text-sm;
}

.ql-editor ul {
  @apply !list-disc;
}

.action-item__question ol {
  @apply !list-decimal !pl-4 !my-2 !text-sm;
}

.ql-editor ol {
  @apply !list-decimal;
}

.action-item__question blockquote,
.ql-editor blockquote {
  @apply !bg-grayscale-300 !rounded-lg !p-2 !my-4 !text-sm;
}
