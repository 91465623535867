.panopticon-tooltip {
  @apply !bg-black opacity-100 !max-w-xs !text-xs !leading-normal !rounded-xl isolate;
  z-index: 51 !important;
}

.panopticon-tooltip.place-top::before,
.panopticon-tooltip.place-bottom::before {
  @apply !border-t-black !border-b-black opacity-100;
}

.panopticon-tooltip.place-top::after {
  @apply !border-t-black opacity-100;
}

.panopticon-tooltip.place-bottom::after {
  @apply !border-b-black opacity-100;
}

.panopticon-tooltip.place-left::before,
.panopticon-tooltip.place-right::before {
  @apply !border-l-black !border-r-black opacity-100;
}

.panopticon-tooltip.place-left::after {
  @apply !border-l-black opacity-100;
}

.panopticon-tooltip.place-right::after {
  @apply !border-r-black opacity-100;
}
