:root {
  /* react-toastify */
  --toastify-color-info: #333333;
  --toastify-color-success: #22c55e;
  --toastify-color-warning: #facc15;
  --toastify-color-error: #ff2164;
}

.Toastify__toast-container {
  width: 350px;
}

/* this fixes mobile positioning */
.Toastify__toast-container.Toastify__toast-container--bottom-center {
  margin-bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__close-button {
  @apply self-center opacity-70;
}

.toast {
  @apply text-sm tablet:text-base p-4 rounded-lg gap-4;
}

.toast__body {
  @apply gap-4 font-bold;
}
