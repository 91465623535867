.invitation {
  @apply w-full h-full flex flex-col justify-center;
}

.invitation__header {
  @apply w-full flex justify-center;
}

.invitation-details {
  @apply flex flex-col justify-center items-center h-full gap-4;
}
.invitation-details__image {
  @apply mb-4;
}

.invitation-details p {
  @apply text-center max-w-xs;
}

.invitation-details__message {
  @apply font-bold text-xl text-black;
}

.invitation-details__company-name {
  @apply text-primary;
}

.invitation-details__button-wrapper {
  @apply mt-4 w-full max-w-xs flex justify-center;
}
