/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import "./fonts.css";

/* Imports */
@import "./layout.css";
@import "./forms.css";
@import "./tables.css";
@import "./graphs.css";
@import "./toasts.css";
@import "./tooltip.css";

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
@layer base {
  body {
    @apply text-grayscale-900;
    font-family: "Manrope", "Helvetica", "Arial", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-extrabold;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  a {
    @apply text-primary;
  }

  p {
    @apply text-grayscale-600 text-sm;
  }

  label {
    @apply block text-xs text-grayscale-500;
  }

  label + * {
    @apply mt-1;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    @apply p-2 w-full border border-grayscale-300;

    border-radius: 10px;
  }

  select {
    @apply bg-white appearance-none bg-no-repeat pr-4;

    min-width: 120px;
    background-image: url("~/src/assets/flow-icons/chevron-down.svg") !important;
    background-position: calc(100% - 0.5rem) 50%;
  }

  textarea {
    resize: none;
  }

  input::placeholder {
    @apply text-grayscale-400;
  }

  /* Tables */
  table {
    margin: 0;
    padding: 0;
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    @apply px-2 py-4 text-sm;
  }

  th {
    @apply flex items-center gap-1 p-2 text-xs text-left text-grayscale-600 bg-white;

    z-index: 1;
    font-weight: 500;
    position: sticky;
    top: 0;
  }

  th:last-child {
    border: 0;
  }

  td {
    @apply relative border-t border-grayscale-300;
  }
}

/* CSS Variables - this maps to the Tailwind config as well */
:root {
  --transparent: "transparent";
  --black: "#000";
  --white: "#FFF";
  --primary: "#194BFB";
  --primary-600: "#003CBE";
  --primary-400: "436CFF";
  --primary-300: "#95ADFF";
  --primary-200: "#BFCDFF";
  --primary-100: "#E8EDFF";
  --secondary: "#1A202C";
  --secondary-300: "#E2E8F0";
  --secondary-200: "#EDF2F7";
  --secondary-100: "#D8E3F8";
  --secondary-50: "#FAFAFA";
  --success: "#22C55E";
  --success-900: "#16A34A";
  --success-500: #22c55e;
  --success-300: "#4ADE80";
  --success-100: "#22C55E1F";
  --warning: "#FACC15";
  --warning-900: "#EAB308";
  --warning-500: "#FF457D";
  --warning-300: "#FDE047";
  --error: "#FF2164";
  --error-900: "#D91C55";
  --error-300: "FF6492";
  --error-100: "#FFE9EF";
  --cyan: "#2FD9BA";
  --cyan-900: "#2AC3A7";
  --cyan-100: "#E0F9F5";
  --grayscale-900: "#1A202C";
  --grayscale-800: "#232B38";
  --grayscale-700: "#2A313C";
  --grayscale-600: "#718096";
  --grayscale-500: "#A0AEC0";
  --grayscale-400: "#CBD5E0";
  --grayscale-300: "#E2E8F0";
  --grayscale-200: "#EDF2F7";
  --grayscale-100: "#F7F9FA";
  --grayscale-50: "#FFFFFF";
}

/* Font Swapping */
.fonts-loaded h2 {
  font-family: "GT Flexa";
  font-weight: 700;
}
