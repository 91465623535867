.card-grid {
  @apply flex flex-col mb-3 gap-3 tablet:grid tablet:grid-cols-2 md:grid-cols-3;
}

.dashboard-card {
  @apply flex flex-col rounded-lg border bg-white border-grayscale-300 p-4;
}

.dashboard-card__header {
  @apply h-10 flex flex-row items-center gap-2;
}

.dashboard-card__controls {
  @apply ml-10;
}

.dashboard-card__options {
  @apply ml-auto;
}

.dashboard-card__header h3 {
  @apply text-base;
}

.dashboard-card__name {
  @apply text-sm;
}

/* mini cards */

.mini-card__value {
  @apply flex items-center gap-2 font-bold;

  font-size: 2rem;
}

.mini-card__info-line {
  @apply text-xs flex items-center gap-2 text-grayscale-600;
}

.mini-card__zero-cash-line {
  @apply mt-3 text-xs flex items-center gap-2 text-grayscale-600;
}

/* animation for the mini cards */
.animate-value {
  will-change: opacity;
  transition: 0.25s opacity ease-in-out;
}

.animate-value--out {
  @apply opacity-0;
}

.animate-value--in {
  @apply opacity-100;
}

/* wrapping divs for scrollable tables */
.bills-table-wrap,
.invoices-table-wrap {
  @apply overflow-auto;

  height: 200px;
}

.subscriptions-table-wrap {
  @apply overflow-auto mt-4;

  height: 325px;
}

/* column definitions for scrollable tables */
.subscriptions-table .sortable-grid__header,
.subscriptions-table .sortable-grid__row {
  grid-template-columns:
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr);
}

.bills-invoices-table .sortable-grid__header,
.bills-invoices-table .sortable-grid__row {
  grid-template-columns:
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr);
}

/* fix for date picker position */
form + .account-picker-button {
  @apply ml-0;
}

/*email icon in invoices card*/
.email-icon {
  @apply inline-flex p-0 items-center gap-2 font-extrabold border rounded-lg flex-shrink-0 transition ease-in-out justify-center ml-auto !w-8 !h-8 border-grayscale-300;
}

.email-icon i {
  display: block;
  width: 14px;
  height: 12px;
}
