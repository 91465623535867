.modal {
  @apply relative isolate;

  z-index: 100;
}

.modal__wrap {
  @apply fixed inset-0 flex items-center justify-center;
}

.modal__panel {
  @apply relative w-full h-full p-4 bg-white tablet:h-auto tablet:max-w-md tablet:rounded-xl tablet:p-8;
}

.modal__close {
  @apply absolute top-2 right-2 p-2;
}

.modal__close:disabled {
  @apply cursor-not-allowed;
}

.modal__backdrop {
  @apply fixed inset-0 bg-black/50;
}

.modal__title {
  @apply mb-5 font-bold;
}
