.bookkeeping-banner {
  @apply relative flex flex-col tablet:flex-row rounded-lg border bg-white border-grayscale-300 p-4 tablet:pb-0;
  grid-column: 1/-1;
}

.fonts-loaded h2.bookkeeping-banner__title {
  @apply font-extrabold text-xl tablet:text-2xl;

  font-family: Manrope;
}

.bookkeeping-banner__text {
  @apply my-4 leading-6;
}

.bookkeeping-calendar {
  @apply mt-8 flex-shrink-0 border border-b-0 border-grayscale-300 p-4 rounded-tl-lg rounded-tr-lg ml-auto;

  width: 312px;
}

.bookkeeping-calendar__grid {
  @apply grid grid-cols-7 gap-y-1;
}

.day__inner {
  @apply flex items-center justify-center text-sm rounded-md h-10;
}

.day--first {
  @apply rounded-tl-md rounded-bl-md;

  background: #f4f7ff;
}

.day--first .day__inner {
  border: 1px solid blue;
}

.day--last {
  @apply rounded-tr-md rounded-br-md;
}

.day--last .day__inner {
  @apply bg-primary text-white;
}

.day--range {
  background: #f4f7ff;
}

.day--today:not(.day--last) {
  @apply font-bold underline;
}

.day--today.day--last {
  @apply font-bold;
}

.day--last-month {
  color: #a0aec0;
}

.books-responded {
  background-image: url("~/src/assets/images/answered-action-items.svg") !important;
  background-size: auto;
  background-position: 100% 50%;
}
