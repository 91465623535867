.percent-changed {
  @apply text-sm rounded-full font-normal flex items-center gap-1 py-1 px-2;
}

.percent-changed--positive {
  @apply text-success !bg-success-100;
}

.percent-changed--negative {
  @apply text-error !bg-error-100;
}
