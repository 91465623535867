.breadcrumb-trail {
  @apply m-0 p-0 list-none flex items-center gap-1 text-xs;
}

.breadcrumb-trail__segment {
  @apply relative pl-2 whitespace-nowrap overflow-hidden text-ellipsis flex-grow-0 flex-shrink min-w-0;
}

.breadcrumb-trail__segment:not(:first-child)::before {
  @apply absolute top-0 left-0;

  content: "/";
}

.breadcrumb-trail__segment:first-child {
  @apply flex-shrink-0 pl-0 mr-1;
}

.breadcrumb-trail__segment:last-child {
  @apply flex-shrink-0;
}

.breadcrumb-trail__segment,
.breadcrumb-trail__link {
  @apply text-grayscale-600 font-normal;
}

.breadcrumb-trail__link:hover {
  @apply text-primary;
}
