.team__cards {
  @apply grid grid-cols-1 gap-3 tablet:grid-cols-3;
}

.team-card {
  @apply flex flex-col items-center pt-10 pb-4 px-4 rounded-lg border bg-white border-grayscale-300 text-sm;
}

.team-controls {
  @apply flex items-end my-4;
}

.team-member__name {
  @apply mt-4 mb-2 font-semibold;
}

.team-member__email {
  @apply h-5 flex items-center gap-2 cursor-pointer text-grayscale-600;
}

.team-member__email:hover .team-member__email-icon {
  @apply block;
}

.team-member__email-icon {
  @apply hidden;
}

.team-member__role {
  @apply capitalize my-4;
}

.team-member__contact {
  @apply font-bold;
}

.team-member__delete-button {
  @apply absolute top-5 right-5;
}

.team-card__wrapper {
  @apply relative;
}
