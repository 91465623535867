.tabset {
  @apply flex items-start my-10 border-b border-grayscale-500;
}

.tabset__item {
}

.tabset__link {
  @apply flex p-4 border-b-2 border-transparent font-extrabold text-grayscale-500;
}

.tabset__link--active {
  @apply border-primary text-grayscale-900;
}
