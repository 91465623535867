/* here we define the column min/max widths for our grid header/rows */
.documents-table .sortable-grid__header,
.documents-table .sortable-grid__row {
  grid-template-columns:
    minmax(150px, 3fr)
    minmax(150px, 3.33fr)
    minmax(150px, 1.67fr)
    minmax(150px, 2fr);
}

.documents-table__header {
  @apply flex items-center gap-1 p-2 text-xs text-left text-grayscale-600;

  font-weight: 500;
}

.documents-table__header--sortable {
  @apply cursor-pointer;
}

.document-table__row-controls {
  @apply ml-auto flex gap-2;
}

/* Viewer */
.document-viewer {
  @apply relative isolate;

  z-index: 100;
}

.document-viewer button {
  @apply focus:outline-none; /* should come back and restyle this after talking to Dimitar */
}

.document-viewer__backdrop {
  @apply fixed inset-0 bg-black/75;
}

.document-viewer__wrap {
  @apply fixed inset-0 flex items-center justify-center;
}

.document-viewer__panel {
  @apply flex flex-col relative w-full max-w-2xl;
}

.document-viewer__document {
  @apply h-full;
}

.document-viewer__nopreview {
  @apply w-full flex flex-col items-center px-8 py-16 rounded-xl bg-white;
}

.document-viewer__nopreview h3 {
  @apply text-lg mb-2;
}

.document-viewer__nopreview p {
  @apply text-xs;
}

.document-viewer__info {
  @apply mt-4 flex items-center justify-between;
}

.document-viewer__info h3 {
  @apply mb-1 text-sm text-white;
}

.document-viewer__info p {
  @apply text-xs text-white;
}

.document-upload__file {
  @apply rounded-lg border border-grayscale-300 p-2 mb-2;
}

.document-upload__file-info {
  @apply bg-grayscale-100 p-2 flex items-center gap-2 rounded-md;
}

.document-upload__file-icon {
  @apply flex-shrink-0;
}

.document-upload__filename {
  @apply font-bold whitespace-nowrap overflow-hidden text-ellipsis;
}

.document-upload__file-size {
  @apply text-grayscale-600 font-bold whitespace-nowrap flex-shrink-0;
}

.document-upload__file-delete {
  @apply ml-auto text-error font-bold text-sm;
}

.document-upload__wrap,
.move-document-form__wrap {
  @apply overflow-auto mb-4;
  height: 300px;
}

.documents__folder {
  @apply p-4 rounded-lg border bg-white border-grayscale-300 h-24 cursor-pointer;
}

.documents__folder:hover .documents__folder-name {
  @apply text-primary;
}

.documents__folder:hover .documents__folder-menu {
  @apply block;
}

.documents__folder-menu {
  @apply hidden;
}

.documents__folder-name {
  @apply font-bold text-grayscale-900 w-full overflow-hidden whitespace-nowrap text-ellipsis;
}

.documents__folder-header {
  @apply w-full flex items-center justify-between;

  /* This isn't exact, but it gets us a similar layout whether or not the menu (which is ~33px high)
  is shown */
  min-height: 2rem;
}

.folder-form__inner-container {
  @apply my-10;
}

.documents__folder-container {
  @apply mb-2 grid grid-cols-4 gap-3;
}

.move-documents-form__folder-container {
  @apply grid-cols-2 cursor-pointer;
}

.document-grid__overlay h3 {
  @apply text-lg mb-2;
}

.document-grid__overlay {
  @apply w-full h-full flex flex-col items-center bg-white opacity-100 justify-center absolute top-0 left-0;
  z-index: 10;
}
