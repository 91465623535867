.form-tree-wrap {
  @apply mt-4 mb-8 pt-4 border-t border-grayscale-300 overflow-auto;

  max-height: 330px;
}

.form-tree__inner-wrapper {
  @apply flex items-center gap-1;
}

.form-tree__inner-wrapper--has-children {
  @apply ml-0;
}

.form-tree ul {
  @apply ml-5;
}

.form-tree .radio {
  display: none;
}

.form-tree .form-tree {
  @apply my-0;

  margin-left: 45px;
}

.form-item {
  @apply m-0 py-2 pl-6 relative;
}

.form-item--has-children {
  @apply pl-0;
}

.form-tree__label {
  @apply flex items-center gap-1 hover:text-primary;
}

.form-tree__label--selected {
  @apply text-primary font-bold;
}

.form-tree__label--disabled {
  @apply text-grayscale-500 font-normal;
}

.form-item--hidden {
  @apply hidden;
}

.form-tree--hidden {
  @apply hidden;
}

.form-tree .form-item:last-child {
  @apply pb-0;
}

.form-tree .form-tree .form-item:before {
  @apply absolute block border-l h-full border-grayscale-300;

  left: -13px;
  top: 4px;
  width: 1px;
  content: "";
}
