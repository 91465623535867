.snapshot-tabs {
  @apply grid grid-cols-2 rounded-lg border border-grayscale-300 mb-4;

  padding: 2px;
}

.snapshot-tabs__button {
  @apply w-full text-center p-2 font-bold text-sm text-grayscale-600 rounded-md;
}

.snapshot-tabs__button--active {
  @apply bg-primary-100 text-primary;
}

.snapshot__description {
  @apply mb-4;
}

.snapshot__form {
  @apply mt-4;
}

.snapshot__form-divider {
  @apply my-2 border-t border-grayscale-300;
}
