.reset-password {
  @apply flex flex-col gap-4;
}

.reset-password__confirm {
  @apply w-full mt-8;
}

.email-valid {
  @apply absolute top-8 right-3;
}
