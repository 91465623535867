.company-picker {
  @apply flex flex-col items-center mx-auto;
}

.company-picker__title {
  @apply text-center mb-4;
}

.company-picker__inner {
  width: 350px;
}

.company-picker__list {
  @apply mt-10 flex flex-col gap-3;
}

.company-picker__list--scrollable {
  @apply mt-0 overflow-y-scroll;

  height: 250px;
}

.company-picker__button {
  @apply w-full flex items-center gap-4 p-2 text-sm font-semibold bg-white border rounded-md border-grayscale-300;
}

.company-picker__button:hover .company-picker__button-select {
  @apply block;
}

.company-picker__button-select {
  @apply hidden ml-auto text-primary;
}

.company-picker__shadow {
  @apply pointer-events-none relative -top-10 opacity-0 w-full h-7 bg-gradient-to-t from-white to-transparent transition-all duration-200;
}

.company-picker__shadow--visible {
  @apply opacity-100 h-10;
}

.company-picker__filter {
  @apply my-4;
}
