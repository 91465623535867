.scroll-container {
  @apply relative;
}

.scroll-shadow {
  @apply pointer-events-none absolute bottom-0 opacity-0 w-full h-7 bg-gradient-to-t from-white to-transparent transition-all duration-200 z-10;
}

.scroll-shadow--visible {
  @apply opacity-100 h-10;
}
