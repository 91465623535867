.workspace {
  @apply relative ml-auto mb-auto;
}

.workspace--compact {
  @apply mb-0;
}

.workspace__popover {
  @apply absolute right-0 z-10 flex flex-col gap-3 px-1 py-2 bg-white rounded-lg border border-grayscale-300 shadow-flowfi;

  top: calc(100% + 4px);
  width: 250px;
}

button.workspace__button {
  @apply flex items-center gap-4 font-bold text-sm border border-grayscale-300;

  padding: 10px;
  border-radius: 10px;
}

.workspace--compact button.workspace__button {
  @apply border-0 gap-2 pr-0;
}

button.workspace__button--route-active {
  @apply bg-grayscale-100;
}

.workspace__link {
  @apply w-full flex flex-row items-center gap-3 text-sm text-grayscale-900 p-2 rounded-lg hover:bg-grayscale-100;
}

.workspace__link span {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.workspace__filter-wrap {
  @apply relative;
}

.workspace__filter-icon {
  @apply pointer-events-none absolute top-3 left-2;
}

input.workspace__filter {
  @apply !pl-8 mb-2 !border-0;
}

.workspace__companies {
}

.workspace__companies--scrollable {
  @apply mt-0 overflow-y-scroll;

  height: 250px;
}

.workspace__shadow {
  @apply pointer-events-none absolute bottom-0 opacity-0 w-full h-7 bg-gradient-to-t from-white to-transparent transition-all duration-200;
}

.workspace__shadow--visible {
  @apply opacity-100 h-10;
}

.workspace__actions {
  @apply border-t border-grayscale-300 pt-2;
}
