.banner {
  @apply flex items-center justify-center py-3 px-8 text-center font-bold text-xs text-white sticky top-0 isolate z-50 tablet:text-sm tablet:mb-0;

  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.banner--primary {
  @apply bg-primary;
}

.banner--error {
  @apply bg-error;
}

.banner--warning {
  @apply bg-warning-900;
}
