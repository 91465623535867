.datepicker__popper {
  @apply z-50;
}

/* React Datepicker Overrides */
.react-datepicker {
  @apply rounded-lg border border-grayscale-300 shadow-flowfi;
}

.react-datepicker__navigation-icon {
  @apply block w-8 top-0;
}

.react-datepicker__navigation--previous {
  @apply left-0;
}

.react-datepicker__navigation-icon--previous {
  @apply right-0;
}

.react-datepicker__navigation--next {
  @apply right-0;
}

.react-datepicker__navigation-icon--next {
  @apply left-0;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  height: 32px !important;
}

.react-datepicker__navigation-icon--previous:before,
.react-datepicker__navigation-icon--next:before {
  @apply w-8 h-8 border-none bg-no-repeat;
}

.react-datepicker__navigation-icon--previous:before {
  @apply transform-none top-0 bg-center;

  background-image: url("~/src/assets/flow-icons/chevron-left.svg") !important;
}

.react-datepicker__navigation-icon--next:before {
  @apply transform-none top-0 bg-center;

  background-image: url("~/src/assets/flow-icons/chevron-right.svg") !important;
}

.react-datepicker__header {
  @apply bg-white rounded-tl-none rounded-tr-none border-b-0;
}

.react-datepicker__day-name {
  @apply text-grayscale-900;
}

.react-datepicker__day--selected {
  @apply bg-primary text-white;
}

.react-datepicker__day--outside-month {
  @apply text-grayscale-600;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  @apply border-b-grayscale-300;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  @apply border-b-white border-t-0;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  @apply border-b-grayscale-300;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  @apply border-t-white;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  @apply border-t-grayscale-300;
}
