.grid-cell {
  @apply px-2 text-sm text-grayscale-900 flex gap-2 items-center max-w-full h-16;
}

.grid-cell--button,
.grid-cell--link {
  @apply py-0 text-grayscale-900 hover:text-primary flex gap-2 items-center;
}

.grid-cell__text {
  @apply text-grayscale-900 whitespace-nowrap overflow-clip text-ellipsis max-w-full;
}

.grid-cell--button .grid-cell__text {
  @apply hover:text-primary;
}

.grid-cell--disabled .grid-cell__text,
.grid-cell--inactive .grid-cell__text {
  @apply text-grayscale-400 hover:text-grayscale-400;
}

.grid-cell--small {
  @apply h-8 gap-3;
}
