.sortable-grid {
  /* @apply relative; */
}

.sortable-grid__header {
  @apply grid;

  z-index: 1;
  font-weight: 500;
  position: sticky;
  top: 0;
}

.sortable-grid__column {
  @apply flex items-center gap-1 p-2 text-xs text-left text-grayscale-600 bg-white;
}

.sortable-grid__column--sortable {
  @apply flex items-center cursor-pointer;
}

.sortable-grid__rows {
  @apply text-sm relative;
}

.sortable-grid__row {
  @apply grid border-t border-grayscale-300 hover:bg-grayscale-100;
}

.sortable-grid__row > div {
  @apply px-2 py-4 text-sm items-center whitespace-nowrap;
}
