.select {
  @apply relative;
}

.select__trigger {
  @apply p-2 pr-7 w-full border border-grayscale-300 relative text-left transition ease-in-out duration-150;

  border-radius: 10px;
}

.select__trigger-text {
  @apply truncate;
}

.select__trigger-icon {
  @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;
}

.select__popover {
  @apply absolute mt-1 w-full max-h-60 flex flex-col gap-3 p-1 bg-white rounded-lg border border-grayscale-300 shadow-flowfi overflow-auto z-10;
}

.select__popover-item {
  @apply p-2 text-sm text-grayscale-900 bg-white rounded-lg cursor-default select-none;
}

.select__popover-item--active {
  @apply bg-grayscale-100;
}

.select__popover-item-text {
  @apply block truncate font-normal;
}

.select__popover-item-text--active {
  @apply font-semibold;
}
