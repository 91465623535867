/* BEM goes out the window when we're styling an external module, sadly */
.pagination {
  @apply flex flex-row mt-3 items-center;
}

.pagination > ul {
  @apply flex items-center justify-start ml-auto mr-4 text-xs;
}

.pagination a {
  @apply flex items-center justify-center w-8 h-8 font-semibold text-grayscale-900 border border-transparent rounded;
}

.pagination .selected a {
  @apply border border-grayscale-300 text-primary;
}

.pagination .disabled a {
  @apply text-grayscale-400;
}

.pagination .previous,
.pagination .next {
  @apply text-base;
}

.pagination__count {
  @apply text-xs text-grayscale-600;
}
