/* we can style certain elements from apex charts here, but it's a little funky */
.apexcharts-legend {
  @apply !overflow-visible;
}

.col-span-1 .apexcharts-legend {
  @apply grid grid-cols-2;
}

.col-span-2 .apexcharts-legend {
  @apply grid grid-cols-3;
}

.graph__marker {
  @apply w-3 h-3 rounded;
}

.apexcharts-tooltip {
  @apply !border-0;
}

.graph__tooltip {
  @apply px-2 bg-black text-white text-xs !border-0;
}

.graph__tooltip-item {
  @apply !flex flex-row items-center gap-2 my-2;
}

.graph__tooltip-label {
  @apply whitespace-nowrap;
}

.graph__tooltip-value {
  @apply !ml-auto;
}

/* Individual markers */
.apexcharts-legend-series {
  @apply relative flex flex-row gap-1 overflow-y-visible;
}

.apexcharts-legend-text {
  @apply whitespace-nowrap overflow-x-hidden overflow-y-visible text-ellipsis;
}

/* Our custom tooltip for legends */
.apexcharts-legend-series:hover .apexcharts-legend-tooltip {
  @apply opacity-100;

  top: -28px;
}

.apexcharts-legend-tooltip {
  @apply text-center opacity-0 absolute bg-black text-white text-xs rounded-md p-1 pointer-events-none;

  top: -20px;
  left: 1rem;
  transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out;
  z-index: 50;
}
