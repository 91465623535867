.company-icon {
  @apply flex flex-shrink-0 items-center justify-center rounded-full bg-grayscale-500 text-white text-center overflow-hidden;
}

.company-icon img {
  @apply w-full h-full;
}

.company-icon--xs {
  @apply w-5 h-5 text-xs;
}

.company-icon--sm {
  @apply w-6 h-6 text-sm;
}

.company-icon--md {
  @apply w-8 h-8 text-base;
}

.company-icon--lg {
  @apply w-12 h-12 text-xl;
}

.company-icon--xl {
  @apply w-16 h-16 text-4xl;
}

.company-icon--2xl {
  @apply w-20 h-20 text-4xl;
}

.company-icon--3xl {
  @apply w-24 h-24 text-4xl;
}
