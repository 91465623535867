.member-row {
  @apply mb-4 text-sm tablet:mb-0 tablet:p-3 tablet:grid tablet:items-center tablet:gap-4 tablet:border-b tablet:border-grayscale-300;

  grid-template-columns:
    minmax(200px, 1.75fr)
    minmax(150px, 1.75fr)
    minmax(150px, 0.5fr)
    minmax(150px, 0.75fr);
}

.member-row__name {
  @apply flex flex-row items-center;
}

.member-row__email {
  @apply whitespace-nowrap text-ellipsis overflow-hidden;
}

.member-row__user-icon {
  @apply mr-3;
}

.member-row__user-name {
  @apply mr-1;
}

.member-row__actions {
  @apply flex flex-row justify-end items-center gap-3;
}

.member-row__delete-button {
  @apply flex flex-row items-center justify-self-end;
}

.member-row__invitation-state {
  @apply text-xs tablet:text-sm bg-grayscale-200 text-grayscale-500 rounded-lg px-2;
}

.member-row__resend-email:disabled {
  @apply text-grayscale-400;
}
