.change-password {
  @apply flex flex-col gap-4;
}

button.change-password__forgot {
  @apply mb-1 text-left text-primary text-xs font-bold bg-transparent border-transparent hover:text-primary-600 focus:text-primary-600;
}

.change-password__confirm {
  @apply w-full mt-8;
}

.change-password__form-error {
  @apply text-xs text-error-500;
}
