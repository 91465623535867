/* Large buttons don't quite fit the Tailwind scaling, so we set a var with the value */
:root {
  --button-lg: 52px;
}

.button {
  @apply inline-flex px-4 items-center justify-center gap-2 font-extrabold border rounded-lg flex-shrink-0 transition ease-in-out;
}

.button + .button {
  @apply ml-2;
}

.button--primary {
  @apply text-white border-primary !bg-primary hover:!bg-primary-600;
}

.button--secondary {
  @apply text-primary border-primary !bg-white hover:border-primary-600 hover:text-primary-600;
}

.button--tertiary {
  @apply text-black border-grayscale-300 hover:text-primary focus:text-primary-600;
}

.button--text {
  @apply text-primary !bg-transparent border-transparent hover:text-primary-600 focus:text-primary-600;
}

.button--success {
  @apply text-white border-success !bg-success hover:!border-success-900 hover:!bg-success-900;
}

.button--error {
  @apply text-white border-error !bg-error hover:!bg-error-900;
}

.button--full-width {
  @apply w-full;
}

.button--no-wrap {
  @apply whitespace-nowrap;
}

.button--disabled,
.button:disabled {
  @apply cursor-not-allowed;
}

.button--primary.button--disabled {
  @apply text-white border-grayscale-400 !bg-grayscale-400;
}

.button--secondary.button--disabled {
  @apply text-grayscale-400 border-grayscale-400 !bg-white;
}

.button--tertiary.button--disabled {
  @apply text-grayscale-400 border-grayscale-300 !bg-white;
}

.button--text.button--disabled {
  @apply text-grayscale-400;
}

.button--lg {
  @apply text-base;

  height: var(--button-lg);
}

.button--md {
  @apply h-10 text-sm;
}

.button--sm {
  @apply h-6 text-xs;
}

.button--with-icon img {
  @apply mr-2;

  width: 16px;
}

.button--icon-only {
  @apply justify-center p-0;
}

.button--lg.button--icon-only {
  width: var(--button-lg);
  height: var(--button-lg);
}

.button--lg.button--icon-only img {
  width: 26px;
}

.button--md.button--icon-only {
  @apply w-10 h-10;
}

.button--md.button--icon-only img {
  width: 24px;
}

.button--sm.button--icon-only {
  @apply w-6 h-6;
}

.button--sm.button--icon-only img {
  width: 18px;
}

.button--borderless {
  @apply !border-opacity-0;
}
