.loader {
  @apply relative inline-flex flex-col items-center justify-center;
}

.loader--fullscreen {
  @apply flex fixed top-0 bottom-0 left-0 right-0 pointer-events-none bg-white/50 isolate;

  z-index: 100;
}

.loader--centered {
  @apply w-full h-full;
}

.loader__indicator {
  @apply relative w-full h-full flex items-center justify-center rounded-full;

  background: linear-gradient(180deg, #0f6fff 0%, rgba(0, 0, 0, 0.1) 25%);
  animation: spin 1s linear infinite;
}

.loader__indicator::after {
  content: "";
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background: #fff;
}

.loader--alt .loader__indicator::after {
  @apply bg-grayscale-100;
}

.loader--sm .loader__indicator {
  width: 18px;
  height: 18px;
}

.loader--md .loader__indicator {
  width: 32px;
  height: 32px;
}

.loader--lg .loader__indicator {
  width: 64px;
  height: 64px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
