.company-page {
  @apply grid gap-4;
}

.company-page--twothirds {
  @apply tablet:grid-cols-flowfi-twothirds;
}

.company-page__form {
  @apply grid gap-4 grid-cols-1 tablet:grid-cols-2;
}

/* users */
.company-page__users {
  @apply tablet:rounded-lg tablet:border tablet:bg-white tablet:border-grayscale-300 tablet:p-4;
}

.company-page__users-header {
  @apply grid items-center gap-4 p-2 text-xs text-left text-grayscale-600 bg-white border-b border-grayscale-300;

  grid-template-columns:
    minmax(200px, 1.75fr)
    minmax(150px, 1.75fr)
    minmax(150px, 0.5fr)
    minmax(150px, 0.75fr);
}

.company-page__user-controls {
  @apply flex flex-col tablet:grid tablet:grid-cols-flowfi-twothirds-reverse tablet:mb-4;
}

.company-icon__button {
  @apply relative;
}

.company-icon__overlay {
  @apply opacity-0 flex flex-col items-center justify-center w-full h-full rounded-full absolute top-0 left-0;

  transition: 0.25s all ease-in-out;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 0 1rem 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(0px);
}

.company-icon__button:hover .company-icon__overlay {
  @apply opacity-100;

  backdrop-filter: blur(5px);
}
